import React from "react";
import styled from "styled-components";

import { SanityFeedYourLifePreview } from "@graphql-types";
import { gradients } from "@util/constants";
import { Container, Gradient, H2, P } from "@util/standard";
import { Button, Image } from "../global";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data: SanityFeedYourLifePreview;
}

const StyledContainer = styled(Container)`
  .gatsby-image-wrapper > img {
    transform: scale(1);
    transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover {
    .gatsby-image-wrapper > img {
      transform: scale(1.1);
      transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }
`;

function FeedYourLifePreview({ data }: Props) {
  if (data == null) {
    return null;
  }

  const { isMobileWidth, isLaptopWidth } = useCheckScreenWidth();

  return (
    <Container margin="0 0 15px 0">
      <Container width="98%" margin="15px auto 0 auto" tabletMargin="4% auto 0 auto" isMobileColumn>
        {data.pageRefs?.map((category, index) => {
          if (category == null || data.pageRefs == null) {
            return null;
          }

          const isLast = index === data.pageRefs?.length - 1;

          return (
            <StyledContainer
              position="relative"
              width="100%"
              tabletWidth="92%"
              margin={isLast ? "0" : "0 15px 0 0"}
              tabletMargin="0 auto 4% auto"
              key={category._id}
            >
              <Container
                flexDirection="column"
                width="72%"
                tabletWidth="50%"
                mobileWidth="100%"
                margin="auto"
                zIndex={2}
                alignItems="center"
              >
                <H2
                  textAlign="center"
                  fontWeight="bold"
                  color="white"
                  margin={!isLaptopWidth ? "0 0 20px 0" : "0 0 140px 0"}
                >
                  {category.pageName}
                </H2>
                <Button
                  text="View"
                  width="max-content"
                  theme="blockWhite"
                  linkTo={`/feed-your-life/${category.slug?.current}`}
                />
              </Container>
              <Image
                minHeight={isMobileWidth ? "300px" : "500px"}
                width="100%"
                isBackground
                image={category.featureImage?.asset?.gatsbyImageData}
              />
              <Gradient gradient={gradients.gradientFromTop} />
            </StyledContainer>
          );
        })}
      </Container>
    </Container>
  );
}

export default FeedYourLifePreview;
