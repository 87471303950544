import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import Hero from "@components/global/hero";
import {
  isSanityBlogPreview,
  isSanityCategoryPanelPreview,
  isSanityColumnContent,
  isSanityCtaBanner,
  isSanityCtaBlock,
  isSanityFeedYourLifePreview,
  isSanityLearnCategoryPreview,
  isSanityProductReferenceCta,
  isSanityRecipePreview,
} from "@util/types";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import ColumnContent from "@components/global/columnContent";
import CtaBlock from "@components/global/ctaBlock";
import ProductReferenceCta from "@components/global/productReferenceCta";
import { useStore } from "@state/store";
import SEO from "@shared/seo";
import RecipePreview from "@components/global/recipePreview";
import BlogPreview from "@components/global/blogPreview";
import CategoryPanelPreview from "@components/global/categoryPanelPreview";
import CtaBanner from "@components/global/ctaBanner";
import FeedYourLifePreview from "@components/feedYourLife/feedYourLifePreview";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

function FeedYourLife({ data }: Props) {
  if (data == null || data.allSanityFeedYourLife == null) {
    return null;
  }

  const { hero, pageContent, seo } = data.allSanityFeedYourLife.nodes[0];

  useEffect(() => {
    useStore.setState({ searchFilters: [] });
  });

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {hero && <Hero data={hero} />}
        <div>
          {pageContent &&
            pageContent.map(data => {
              if (isSanityFeedYourLifePreview(data)) {
                return <FeedYourLifePreview data={data} key={data._key} />;
              }
              if (isSanityLearnCategoryPreview(data)) {
                return <LearnCategoryPreview data={data} key={data._key} />;
              }
              if (isSanityColumnContent(data)) {
                return <ColumnContent data={data} key={data._key} />;
              }
              if (isSanityCtaBlock(data)) {
                return <CtaBlock data={data} key={data._key} />;
              }
              if (isSanityProductReferenceCta(data)) {
                return <ProductReferenceCta data={data} key={data._key} />;
              }
              if (isSanityRecipePreview(data)) {
                return <RecipePreview data={data} key={data._key} />;
              }
              if (isSanityBlogPreview(data)) {
                return <BlogPreview data={data} key={data._key} />;
              }
              if (isSanityCategoryPanelPreview(data)) {
                return <CategoryPanelPreview data={data} key={data._key} />;
              }
              if (isSanityCtaBanner(data)) {
                return <CtaBanner data={data} key={data._key} />;
              }
              return null;
            })}
        </div>
      </Layout>
    </>
  );
}

export default FeedYourLife;

export const query = graphql`
  {
    allSanityFeedYourLife {
      nodes {
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        pageContent {
          ... on SanityFeedYourLifePreview {
            _key
            _type
            pageRefs {
              pageName
              slug {
                current
              }
              featureImage {
                ...sanityFullWidthImage
              }
            }
          }
          ... on SanityRecipePreview {
            ...sanityRecipePreview
          }
          ... on SanityProductReferenceCta {
            ...sanityProductReferenceCta
          }
          ... on SanityLearnCategoryPreview {
            ...sanityLearnCategoryPreview
          }
          ... on SanityCtaBlock {
            ...sanityCtaBlock
          }
          ... on SanityBlogPreview {
            ...sanityBlogPreview
          }
          ... on SanityCtaBanner {
            _key
            _type
            ctas {
              ...sanityCta
            }
            bannerText {
              ...sanityColumn
            }
          }
          ... on SanityColumnContent {
            ...sanityColumnContent
          }
          ... on SanityCategoryPanelPreview {
            ...sanityCategoryPanelPreview
          }
        }
      }
    }
  }
`;
